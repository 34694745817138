<template>
  <div class="scrubber50">
    <div class="introduce">
      <div class="detailed detailed1">
        <div class="left container">
          <div class="content white">
            <h3></h3>
            <h4>Scrubber 50 {{ this.$t("scrubber50.scrubber50") }}</h4>
            <p>
              {{ this.$t("scrubber50.RobotsForCleaning") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="video">
      <video
        src="https://enway-official.s3.ap-northeast-1.amazonaws.com/enway-video/2-3/s50.d48ec133.mp4"
        controls
        autoplay
        muted
        loop
        width="100%"
      ></video>
    </div>
    <div class="parameter container">
      <img
        class="photo"
        src="../../assets/scrubber50/Specifications-Img-Vacuum-40-min-1.0591186c.png"
        alt=""
      />
      <div class="message">
        <h4 class="title">
          {{ this.$t("scrubber50.Scrubber50Specification") }}
        </h4>
        <p>
          <span class="textRight">{{ this.$t("scrubber50.ProductSize") }}</span
          ><span>860（L）* 700（W）* 1030（H）mm</span>
        </p>
        <p>
          <span class="textRight">{{
            this.$t("scrubber50.BatteryCapacity")
          }}</span
          ><span>Li-ion 40 Ah/60 Ah</span>
        </p>
        <p>
          <span class="textRight">{{
            this.$t("scrubber50.ContinuousUptime")
          }}</span
          ><span>2-6 h</span>
        </p>
        <p>
          <span class="textRight">{{
            this.$t("scrubber50.KiyoaraiWidth")
          }}</span
          ><span>500 mm</span>
        </p>
        <p>
          <span class="textRight">
            {{ this.$t("scrubber50.SensorConfiguration") }}</span
          ><span
            >{{this.$t('scrubber50.dispose')}}</span
          >
        </p>
        <p>
          <span class="textRight">{{
            this.$t("scrubber50.ClimbingSlope")
          }}</span
          ><span>8%</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang='less'>
.scrubber50 {
  .introduce {
    .container {
      display: flex;
      align-items: center;
      height: 700px;
      .white {
        color: #fff;
      }
      h3 {
        width: 512px;
        font-size: 30px;
      }
      h4 {
        width: 650px;
        font-size: 44px;
        font-weight: 700;
      }
      p {
        margin-top: 40px;
        display: inline-block;
        width: 700px;
        font-size: 16px;
      }
    }
    .left {
      display: flex;
      justify-content: flex-start;
    }
    .right {
      color: #000000;
      display: flex;
      justify-content: flex-end;
    }
    .detailed {
      background: no-repeat center;
      background-size: cover;
    }
    .detailed1 {
      background-image: url("~@/assets/scrubber50/Smart-Obstacle-Avoidance-D.-min.7b920454.png");
    }
  }
  .video {
    padding: 60px 150px;
  }
  .parameter {
    box-sizing: border-box;
    height: 647px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .photo {
      flex-shrink:0;
      width: 577px;
      height: 560px;
    }
    .message {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 347px;
      h4 {
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        line-height: 100px;
      }
      p {
        padding: 10px;
        font-size: 16px;
        color: #000;
        line-height: 1.2;
        display: flex;
        span {
          color: #0064e6;
          line-height: 24px;
        }
        .textRight {
          flex-shrink: 0;
          width: 210px;
          text-align: right;
          color: #000;
        }
        span:last-child{
          margin-left: 40px;
        }
      }
    }
  }
  // 当屏幕宽度小于860px时
  @media screen and (max-width: 860px) {
    .introduce {
      h3 {
        width: 100% !important;
        font-size: 20px !important;
      }
      h4 {
        width: 100% !important;
        font-size: 26px !important;
      }
      p {
        width: 100% !important;
      }
      .content {
        padding: 0 10px;
        width: 100%;
      }
      .container {
        height: 400px !important;
      }
    }
    .video {
      padding: 0;
    }
    .parameter {
      padding: 30px 20px;
      width: unset !important;
      height: unset !important;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      .photo {
        width: 100%;
        height: unset;
      }
      .message {
        height: unset;

        h4 {
          font-size: 16px !important;
        }
        p {
          width: 100%;
          font-size: 18px !important;
          span {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
