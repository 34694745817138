<template>
  <div>
    <div class="header">
      <div>
        <p>Scrubber 50</p>
        <p>{{ this.$t("scrubber50.scrubber50") }}</p>
      </div>
    </div>
    <div class="content">
      <video
        controls
        autoplay
        muted
        loop
        playsinline
        webkit-playsinline
        x5-playsinline
        width="100%"
        src="https://enway-official.s3.ap-northeast-1.amazonaws.com/enway-video/2-3/s50.d48ec133.mp4"
      ></video>
    </div>
    <div class="footer">
      <img
        width="100%"
        src="../../assets/scrubber50/mobileSpecifications-Img-Vacuum-40-min-1.0591186c.png"
        alt=""
      />
      <p class="title">{{ this.$t("scrubber50.Scrubber50Specification") }}</p>
      <ul class="item-list">
        <li>
          <p>{{ this.$t("scrubber50.ProductSize") }}</p>
          <p>860（L）* 700（W）* 1030（H）mm</p>
        </li>
        <li>
          <p>{{ this.$t("scrubber50.BatteryCapacity") }}</p>
          <p>Li-ion 40 Ah/60 Ah</p>
        </li>
        <li>
          <p>{{ this.$t("scrubber50.ContinuousUptime") }}</p>
          <p>2-6 h</p>
        </li>
        <li>
          <p>{{ this.$t("scrubber50.KiyoaraiWidth") }}</p>
          <p>500 mm</p>
        </li>
        <li>
          <p>{{ this.$t("scrubber50.SensorConfiguration") }}</p>
          <p>
            {{ this.$t("scrubber50.dispose") }}
          </p>
        </li>
        <li>
          <p>{{ this.$t("scrubber50.ClimbingSlope") }}</p>
          <p>8%</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.header {
  background: url("../../assets/scrubber50/Smart-Obstacle-Avoidance-D.-min.7b920454.png")
    no-repeat center;
  background-size: cover;
  height: 56vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 15.4667vw;
    margin-left: 19.2vw;
  }
  p {
    font-family: "Meiryooo";
    color: #ffffff;
  }
  p:first-child {
    font-size: 7.4667vw;
    font-weight: bold;
    line-height: 7.2vw;
  }
  p:last-child {
    font-size: 3.7333vw;
    font-weight: 500;
    line-height: 5.0667vw;
  }
}
.content {
  video {
    vertical-align: bottom;
  }
}
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin: 4vw 0;
  }
  .item-list {
    li {
      display: flex;
      margin: 0 9.0667vw;
      margin-bottom: 3.2vw;
      p {
        font-size: 3.2vw;
        font-family: "Meiryooo";
        line-height: 5.3333vw;
      }
      p:first-child {
        flex-shrink: 0;
        width: 23vw;
        color: #000;
        text-align: right;
        margin-right: 2.6667vw;
      }
      p:last-child {
        color: #0064e6;
      }
    }
    li:nth-child(2) {
      p:first-child {
        width: 28vw;
        margin-left: -5vw;
      }
    }
    li:last-child {
      margin-bottom: 10.6667vw;
    }
  }
}
</style>
